<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    border
    style="width:100%;color:#858585;"
    :header-cell-style="tableHeaderCellStyle">
    <el-table-column
      label="序号"
      type="index"
      align="center"
      width="50">
    </el-table-column>
    <!-- 表头开始 -->
    <el-table-column
      label="角色名称"
      width="170"
      prop="name"
      align="center">
    </el-table-column>
    <el-table-column
      label="角色标识"
      prop="authority"
      show-overflow-tooltip
      align="center">
    </el-table-column>

    <el-table-column
      prop="createTime"
      label="创建时间"
      align="center">
    </el-table-column>
    <el-table-column
      prop="updateTime"
      label="更新时间"
      align="center">
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
     align="center">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="view(scope.row.id)"><i class="el-icon-view"></i>查看</el-button>
        <el-button type="text" size="small" @click="edite(scope.row.id)"><i class="el-icon-edit"></i>编辑</el-button>
         <el-button type="text" size="small" style="margin-right:10px" @click="treeRoot(scope.row.id)"><i class="el-icon-circle-plus"></i>权限</el-button>
         <el-popconfirm title="是否确认删除当前角色？" @confirm="remove(scope.row.id)">
            <el-button type="text" size="small" slot="reference"><i class="el-icon-delete"></i>删除</el-button>
        </el-popconfirm>

      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <div class="page">
  <!-- left -->
  <span class="pageLeft">共 {{count}} 条记录 第 {{currentPage}} / {{tatalPage}}页</span>
  <!-- right -->
  <el-pagination
      @size-change="SizeChange"
      background
      @current-change="CurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="onlyPage"
      :pager-count="5"
      layout="sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
  </div>
</div>
</template>

<script>
import {getRoleList,viewRole,deleteRole,getCurrentTree,getAllTree} from '@/api/role.js'
  export default {
    data() {
      return {
        tableData: [],
        editId:null,//编辑id\
        treeId:null,
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:10,// 当前页面可以展示多少条数据,默认10 
      }
    },
    mounted(){
      //初始化获取列表数据
      this.getData(1,10); 
    },

    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
        },
      // getData
      getData(currentPage,onlyPage){
              const rLoading = this.openLoading();// 开始loading
              getRoleList({
              currentPage: currentPage,
              pageSize: onlyPage
            }).then((res)=>{
              if(res.data.status == 200){
                this.tableData = res.data.data.records;
                this.tatalPage =parseInt(res.data.data.size);
                this.count =parseInt(res.data.data.total);
                rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },

      // 分页相关
      SizeChange(val){
        this.onlyPage = val;
        this.getData(this.currentPage,this.onlyPage);
      },
      CurrentChange(val){
        this.currentPage = val;
        this.getData(this.currentPage,this.onlyPage);
      },
      prevClick(val){
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage);

      },
      nextClick(val){
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage);

      },
//业务
     //获取基本信息
      getBaseInfo(id){
        viewRole({
              id:id
          }).then(res=>{
              if(res.data.status==200){
              this.$parent.viewForm = res.data.data;
              this.$parent.ediForm.name = res.data.data.name;
              this.$parent.ediForm.indentity = res.data.data.authority;
            }else{
                this.$message.error(res.data.message);
              }
            }).catch(err=>{
              this.$message.error(err);
          })
      },
       //查看
      view(id){
         this.getBaseInfo(id);
         this.$parent.viewdiog = true;
      },
      //编辑
      edite(id){
          this.editId = id;
          this.getBaseInfo(id);//回显
          this.$parent.edite = true;
      },
      //删除
      remove(id){
        deleteRole({
          id:id
        }).then(res=>{
          if(res.data.status == 200){
            this.$notify({
                  message: '删除成功！',
                  type: 'success',
                  duration:800
              });
            this.getData(1,10); //reload table data
          }else{
            this.$message.error(res.data.message);
          }
        }).catch(err=>{
                  this.$message.error(err);
            })
      },
      //tree--root
      treeRoot(id){
        this.treeId = id ;
        this.$parent.checkData =null;//每次都先清空所选中的
        //1.先获取所有表单
        getAllTree({
        }).then(res=>{
          if(res.data.status == 200){
              //console.log("data",res.data.data);
              this.$parent.treeData = res.data.data
          }else{
            this.$message.error(res.data.message);
          }
        }).catch(err=>{
              this.$message.error(err);
            })
        //2.获取默认选中的
        getCurrentTree({
          id:id
        }).then(res=>{
          if(res.data.status==200){
            this.$parent.checkData = res.data.data;
          }else{
            this.$message.error(res.data.message);
          }
        }).catch(err=>{
                  this.$message.error(err);
            })
        this.$parent.addroot = true;
      }
    }
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
  margin: 0 !important;
}
.resoult{
  text-align: left;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>